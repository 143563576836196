import { GatsbyBrowser } from 'gatsby';

import { AuthProvider } from 'contexts/AuthProvider';
import { ThemeProvider } from 'contexts/ThemeProvider';
import { FaqSearchProvider } from 'contexts/FaqSearchProvider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeProvider>
    <AuthProvider>
      <FaqSearchProvider>{element}</FaqSearchProvider>
    </AuthProvider>
  </ThemeProvider>
);
